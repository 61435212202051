export enum ESTATES_ENUM {
  PHASE_ONE = "phase-one",
  PHASE_TWO = "phase-two",
  PHASE_THREE = "phase-three",
  TIARA = "tiara-one",
  TIARA_TWO = "tiara-two",
  CITY_FTZ = "amen-city-ftz",
}

export const PROD_BASE_URL = "https://amenestate.com";
export const CONTACT_US_API_URL = "https://api.amenestate.com/v1/amen-contact-us";
export const SCHEDULE_INSPECTION_API_URL = "https://api.amenestate.com/v1/amen-inspection";
export const BUY_PROPERTY_API_URL = "https://api.amenestate.com/v1/amen-buy-property";
export const SHORTLET_API_URL = "https://api.amenestate.com/v1/amen-shortlet";

export const DEFAULT_API_ERROR = "There was an error processing your request";
export const DEFAULT_API_SUCCESS =
  "Your request has been sent. We'll get in touch with you shortly";
